body,
html {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: black;
}

.main-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 1px;
  text-align: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  gap: 0.5%;
}

.load-more-btn {
  
  background-color: #e50914;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  transition: background-color 0.3s ease;
}

.load-more-btn:hover {
  background-color: #c11924; /* Slightly darker shade on hover */
}

.btn-space {
  height: 100%;
}

.no-results {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
}
