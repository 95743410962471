.insideCard {
  position: relative;
}

.Movie-not-found {
  color: white;
  font-size: xx-large;
}

.top-inside {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

.inside-info {
  margin-top: 15%;
  margin-left: 7%;
  margin-right: 50%;
  color: white;
}

.inside-info > div {
  margin-bottom: 10px;
}

.bottom {
  height: 100px;
}

.movie-inside-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1%;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  border-radius: 10px;
  padding: 5px 10px;
}

.text-description {
  font-family: "Roboto", sans-serif;
  border-radius: 10px;
  padding: 5px 10px;
}

.text-description {
  width: 60%;
  font-size: 1.2em;
}

.movie-inside-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.mib-play-btn {
  width: 350px;
  height: 55px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6%;
  transform: scale(1);
  transition: transform 0.3s ease-out;
}

.mib-bt-div a {
  text-decoration: none; /* Remove underline */
}

.mib-play-btn:hover {
  transform: scale(1.015);
  transition: transform 0.3s ease;
}

.add-favorite-btn {
  width: 55px;
  height: 55px;
  color: white;
  background-color: rgb(48, 48, 48);
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-favorite-btn:hover {
  background-color: rgb(130, 130, 130);
  transition: background-color 0.6s ease;
}

.mib-play-btn txt,
.add-favorite-btn .like {
  font-size: 1.5em;
  font-weight: bold;
}

.mib-play-btn plBt {
  font-size: 1.5em;
  font-weight: bold;
  font-size: 20px;
}

.like {
  font-size: 25px;
}

.circle-inside {
  font-size: 3.5px;
  align-items: center;
  color: #acacac;
}

.movie-inside-genre {
  display: flex;
  border-radius: 25px;
  padding: 5px;
}

.genre {
  margin-right: 5px;
  padding: 5px; /* Add padding */
  border-radius: 5px; /* Add border radius */
}

/* Define different background colors for genres */
.genre:nth-child(odd),
.genre:nth-child(even) {
  background-color: rgb(76, 76, 76);
}

.movie-inside-logo {
  width: 100%;
  bottom: 0%;
  left: 0%;
  position: absolute;
}

.movie-inside-logo-div {
  width: 60%;
  height: 50%;
  position: relative;
}

.button-More-less {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
  color: rgb(157, 206, 238);
  font: 0.8em sans-serif;
}

.movie-backdrop {
  background-size: cover;
  background-position: center;
  height: 115vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  box-shadow:
    inset 500px 0 400px -50px rgba(0, 0, 0, 1),
    inset 0 -300px 200px -50px rgba(0, 0, 0, 1);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .top-inside {
    flex-direction: column;
    margin-top: 5%;
    align-items: flex-start; /* Align items to the left */
    padding-left: 5%; /* Add padding to move items from the edge */
  }

  .inside-info {
    margin: 5% 0; /* Adjusted margin */
    text-align: left; /* Align text to the left */
    width: 90%; /* Reduced width for better alignment */
  }

  .movie-inside-description {
    flex-direction: row;
    align-items: center; /* Center text on small screens */
    gap: 10px; /* Increased gap */
  }

  .text-description {
    width: 100%;
    font-size: 1em;
    text-align: left; /* Align text to the left */
    margin-bottom: 10px; /* Add space below */
  }

  .movie-backdrop {
    background-size: cover;
    background-position: center;
    height: 20vh;
    position: relative;
    margin-top: 10px;
  }

  .movie-inside-buttons {
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%; /* Full width on small screens */
    margin-left: 5%; /* Adjust left margin for alignment */
    margin-bottom: 10px; /* Add space below */
  }

  .mib-play-btn {
    width: 90%;
    height: 45px;
  }

  .add-favorite-btn {
    width: 90%;
    height: 45px;
  }

  .movie-inside-logo-div {
    width: 100%;
    height: auto;
    margin-top: 10vh; /* Adjusted top margin */
    margin-bottom: 10px; /* Add space below */
    text-align: center; /* Center align the content */
  }

  .movie-inside-logo-div img {
    width: 90%;
    border-radius: 8px; /* Rounded corners for the image */
    margin-bottom: 10px; /* Space below the image */
  }

  .movie-inside-genre {
    justify-content: flex-start; /* Align genres to the left */
    width: 100%;
    flex-direction: row;
    gap: 10px; /* Increased space between genre tags */
    margin-bottom: 10px; /* Add space below */
  }

  .genre {
    margin-bottom: 5px; /* Add margin at the bottom for better spacing */
  }
}


